import { inject } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

const icons = new Map<string, string>([
  ['about', '/assets/icons/about.svg'],
  ['add_member', '/assets/icons/add_member.svg'],
  ['add_service_member', '/assets/icons/add_service_member.svg'],
  ['add', '/assets/icons/add.svg'],
  ['app', '/assets/icons/app.svg'],
  ['arrow_back', '/assets/icons/arrow_back.svg'],
  ['build_workspace', '/assets/icons/build_workspace.svg'],
  ['checklist', '/assets/icons/checklist.svg'],
  ['clean_workspace', '/assets/icons/clean_workspace.svg'],
  ['close', '/assets/icons/close.svg'],
  ['custom_document_type', '/assets/icons/custom_document_type.svg'],
  ['dashboard', '/assets/icons/dashboard.svg'],
  ['delete_forever', '/assets/icons/delete_forever.svg'],
  ['delete', '/assets/icons/delete.svg'],
  ['disconnect_member', '/assets/icons/disconnect_member.svg'],
  ['download', '/assets/icons/download.svg'],
  ['edit_details', '/assets/icons/edit_details.svg'],
  ['edit_documents', '/assets/icons/edit_documents.svg'],
  ['edit_records', '/assets/icons/edit_records.svg'],
  ['email', '/assets/icons/email.svg'],
  ['error', '/assets/icons/error.svg'],
  ['fold', '/assets/icons/fold.svg'],
  ['folder_bin', '/assets/icons/folder_bin.svg'],
  ['folder_workspaces', '/assets/icons/folder_workspaces.svg'],
  ['json', '/assets/icons/json.svg'],
  ['level_admin', '/assets/icons/level_admin.svg'],
  ['level_none', '/assets/icons/level_none.svg'],
  ['level_readonly', '/assets/icons/level_readonly.svg'],
  ['level_standard', '/assets/icons/level_standard.svg'],
  ['level_superadmin', '/assets/icons/level_superadmin.svg'],
  ['login', '/assets/icons/login.svg'],
  ['logout', '/assets/icons/logout.svg'],
  ['member', '/assets/icons/member.svg'],
  ['members', '/assets/icons/members.svg'],
  ['menu', '/assets/icons/menu.svg'],
  ['no_access', '/assets/icons/no_access.svg'],
  ['record_type', '/assets/icons/record_type.svg'],
  ['reference_data', '/assets/icons/reference_data.svg'],
  ['reset_document', '/assets/icons/reset_document.svg'],
  ['restore_from_bin', '/assets/icons/restore_from_bin.svg'],
  ['restore', '/assets/icons/restore.svg'],
  ['save', '/assets/icons/save.svg'],
  ['select_all', '/assets/icons/select_all.svg'],
  ['service_member', '/assets/icons/service_member.svg'],
  ['settings', '/assets/icons/settings.svg'],
  ['terms_of_use', '/assets/icons/terms_of_use.svg'],
  ['translation', '/assets/icons/translation.svg'],
  ['unfold', '/assets/icons/unfold.svg'],
  ['unknown', '/assets/icons/unknown.svg'],
  ['update_records', '/assets/icons/update_records.svg'],
  ['upload', '/assets/icons/upload.svg'],
  ['user_data', '/assets/icons/user_data.svg'],
  ['workspace_data', '/assets/icons/workspace_data.svg'],
  ['workspace_type_app', '/assets/icons/workspace_type_app.svg'],
  ['workspace_type_app_add', '/assets/icons/workspace_type_app_add.svg'],
  ['workspace_type_client', '/assets/icons/workspace_type_client.svg'],
  ['workspace_type_config', '/assets/icons/workspace_type_config.svg'],
  ['workspace_type_config_add', '/assets/icons/workspace_type_config_add.svg'],
  ['workspace_type_content', '/assets/icons/workspace_type_content.svg'],
  ['workspace_type_content_add', '/assets/icons/workspace_type_content_add.svg'],
  ['workspaces', '/assets/icons/workspaces.svg'],
  ['yaml', '/assets/icons/yaml.svg'],
]);

export function registerSvgIcons() {
  const iconRegistry = inject<MatIconRegistry>(MatIconRegistry);
  const sanitizer = inject<DomSanitizer>(DomSanitizer);

  for (const [name, url] of icons) {
    iconRegistry.addSvgIcon(name, sanitizer.bypassSecurityTrustResourceUrl(url));
  }
}
